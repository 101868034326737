// 负责人电话验证
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(str);
}

export function isvalidGate(str) {
  const reg = /^(?:10|[1-9])$/;
  return reg.test(str);
}

export function guardFall(str) {
  const reg = /^\d{6}$/;
  return reg.test(str);
}
